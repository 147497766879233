import { i18n } from "@lingui/core";

export const getLanguageOptions = () => {
  return [
    { value: "en", label: i18n._("Angleščina") },
    { value: "sl", label: i18n._("Slovenščina") },
    { value: "de", label: i18n._("Nemščina") },
    { value: "hr", label: i18n._("Hrvaščina") },
    { value: "sr", label: i18n._("Srbščina") },
    { value: "it", label: i18n._("Italijanščina") },
    { value: "es", label: i18n._("Španščina") },
  ];
};
