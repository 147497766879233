import { LimePageHeader } from "@/Components/LimePageHeader";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { LimeSingleSelect } from "@/Components/NextBase/LimeSelect";
import { api } from "@/lib/api-client";
import { parseDate } from "@internationalized/date";
import { t, Trans } from "@lingui/macro";
import { useForm, zodResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  Button,
  DateRangePicker,
  getKeyValue,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import dayjs from "dayjs";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

export const StockTaking = () => {
  const [dateRange, setDateRange] = useState({
    start: parseDate(dayjs().subtract(1, "year").format("YYYY-MM-DD")),
    end: parseDate(dayjs().format("YYYY-MM-DD")),
  });

  // APIS
  const {
    data: sessionsData,
    refetch,
    isFetching,
    processedErrorMessage,
  } = api.stockTaking.useGetStockTakingSessions({
    startDate: dateRange.start.toString(),
    endDate: dateRange.end.toString(),
  });

  const [newSessionModalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const columns: { key: string; label: string }[] = [
    { key: "dateStarted", label: t`Datum` },
    { key: "location", label: t`Lokacija` },
    { key: "status", label: t`Status` },
  ];

  return (
    <>
      <LimePageHeader
        title={t`Inventura`}
        rightSection={{
          label: t`Začni novo inventuro`,
          onClick: () => openModal(),
          identifier: "start-stock-taking",
        }}
      ></LimePageHeader>

      <div className="flex flex-col gap-4 px-4 py-4 md:px-8">
        <DateRangePicker
          value={dateRange}
          onChange={setDateRange}
          label={t`Datum`}
        ></DateRangePicker>
        <ErrorCard message={processedErrorMessage}></ErrorCard>
        <Table removeWrapper>
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={sessionsData?.stockTakingSessions || []}
            isLoading={isFetching}
            loadingContent={<Spinner color="primary" />}
          >
            {(item) => (
              <TableRow
                key={item.id}
                as={Link}
                href={`/dashboard/stock-taking/${item.id}`}
                className="cursor-pointer rounded-md hover:bg-gray-100"
              >
                {(columnKey) => (
                  <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <NewSessionModal
        opened={newSessionModalOpened}
        onClose={closeModal}
        refetch={refetch}
      ></NewSessionModal>
    </>
  );
};

const newSessionSchema = z.object({ locationId: z.coerce.number() });

const NewSessionModal = ({
  opened,
  onClose,
  refetch,
}: {
  opened: boolean;
  onClose: () => void;
  refetch: () => void;
}) => {
  const navigate = useNavigate();

  // APIS
  const { data: locationData, isFetching: isFetchingLocations } =
    api.location.useGetLocations({
      page: 1,
      perPage: 100,
      sortBy: "labelAsc",
    });

  const { mutateAsync: startSession, isPending } =
    api.stockTaking.usePostStockTakingSession();

  const form = useForm<z.infer<typeof newSessionSchema>>({
    validate: zodResolver(newSessionSchema),
  });

  const handleSubmit = async (values: z.infer<typeof newSessionSchema>) => {
    const response = await startSession({
      locationId: values.locationId,
    });

    toast.success(t`Inventura uspešno začneta`);

    navigate(`/dashboard/stock-taking/${response.createdSession.id}`);

    onClose();
    refetch();
  };

  return (
    <Modal isOpen={opened} title={t`Začni novo inventuro`} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <Trans>Nova inventura</Trans>
        </ModalHeader>
        <ModalBody>
          <form
            className="flex flex-col gap-2"
            onSubmit={form.onSubmit(handleSubmit)}
          >
            <LimeSingleSelect
              items={
                locationData?.locations.map((location) => ({
                  key: location.locationId.toString(),
                  label: location.label,
                })) || []
              }
              {...form.getInputProps("locationId")}
              label={t`Lokacija`}
              isLoading={isFetchingLocations}
            ></LimeSingleSelect>
            <Button color="primary" type="submit" isLoading={isPending}>
              <Trans>Začni inventuro</Trans>
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
