import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import {
  Box,
  type BoxProps,
  Flex,
  type FlexProps,
  Tabs,
  type TabsTabProps,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import classes from "./PageHeader/PageHeader.module.css";
import { cn } from "@/utils";

type TabProps = {
  label: string;
};

export type LimePageTabs = {
  activeTab: string;
  onChangeTab: (tab: string | null) => void;
  tabs: (TabProps & TabsTabProps)[];
  rightSection?: React.ReactNode;
  tabRightSectionGap?: FlexProps["gap"];
  flexDirection?: FlexProps["direction"];
};

export const LimePageTabs = ({
  activeTab,
  onChangeTab,
  tabs,
  rightSection,
  tabRightSectionGap,
  ...props
}: LimePageTabs & BoxProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isMiddleAligned = !isMobile && !rightSection;

  return (
    <Box
      mih={"54px"}
      pos={"relative"}
      px={isMobile ? undefined : "xl"}
      {...props}
    >
      <Flex
        style={{ display: !isMiddleAligned ? "flex" : "contents" }}
        justify={"space-between"}
        align={!rightSection && !isMobile ? "center" : undefined}
        direction={props.flexDirection || (isMobile ? "column" : "row")}
        gap={tabRightSectionGap || 0}
      >
        <Tabs
          classNames={{
            root: classes.tabs_root,
            list: classes.tabs_list,
          }}
          value={activeTab}
          onChange={onChangeTab}
          keepMounted={false}
          h={"54px"}
        >
          <Tabs.List
            px={"md"}
            data-nobottomborder={"true"}
            justify={isMiddleAligned ? "center" : undefined}
          >
            {tabs.map((tab) => {
              const isActive = tab.value === activeTab;

              const textColor = isActive ? Color.Brand : Color.SecondaryText;

              return (
                <Tabs.Tab key={tab.value} {...tab}>
                  <div className="flex w-fit items-center gap-2">
                    {tab.leftSection ? (
                      <div
                        className={cn("h-4 w-4 text-secondaryText", {
                          "text-brand": isActive,
                        })}
                      >
                        {tab.leftSection}
                      </div>
                    ) : null}
                    <Text variant={TextVariant.BodyEmphasized} c={textColor}>
                      {tab.label}
                    </Text>
                  </div>
                </Tabs.Tab>
              );
            })}
          </Tabs.List>
        </Tabs>

        {rightSection}
      </Flex>

      <Box
        style={{
          width: isMobile
            ? "100%"
            : "calc(100% - var(--mantine-spacing-xl) * 2)",
          height: "2px",
          backgroundColor: Color.Divider,
          position: "absolute",
          top: "52px",
          zIndex: -1,
          left: isMobile ? 0 : "var(--mantine-spacing-xl)",
          right: 0,
        }}
      ></Box>
    </Box>
  );
};
