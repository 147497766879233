import {
  DeleteStockTakingSession,
  GetStockTakings,
  GetStockTakingSession,
  PostEndStockTakingSession,
  PostStockTaking,
  PutStockTakingSession,
} from "@/server-types";
import { useCustomMutation } from "../custom-mutation";
import { del, get, post, put } from "../request-helpers";
import { useCustomQuery } from "../custom-query";
import { keepPreviousData } from "@tanstack/react-query";

export const usePostStockTakingSession = () => {
  return useCustomMutation({
    mutationFn: (body: PostStockTaking["body"]) =>
      post<PostStockTaking>(`/stock-taking`, body),
  });
};

export const useGetStockTakingSessions = (query: GetStockTakings["query"]) => {
  return useCustomQuery({
    queryKey: ["getStockTakingSessions", query],
    queryFn: () => get<GetStockTakings>(`/stock-taking`, query),
    placeholderData: keepPreviousData,
  });
};

export const useGetStockTakingSessionById = (sessionId: number) => {
  return useCustomQuery({
    queryKey: ["getStockTakingSessionById", sessionId],
    queryFn: () => get<GetStockTakingSession>(`/stock-taking/${sessionId}`),
  });
};

export const usePutStockTakingSessionById = () => {
  return useCustomMutation({
    mutationFn: ({
      body,
      sessionId,
    }: {
      body: PutStockTakingSession["body"];
      sessionId: number;
    }) => put<PutStockTakingSession>(`/stock-taking/${sessionId}`, body),
  });
};

export const useEndStockTakingSession = () => {
  return useCustomMutation({
    mutationFn: (sessionId: number) =>
      post<PostEndStockTakingSession>(`/stock-taking/${sessionId}/end`),
  });
};

export const useDeleteStockTakingSession = () => {
  return useCustomMutation({
    mutationFn: (sessionId: number) =>
      del<DeleteStockTakingSession>(`/stock-taking/${sessionId}`),
  });
};
