import { api } from "@/lib/api-client";
import { useSidebarStore } from "@/stores/useSidebarStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Popover, Text } from "@mantine/core";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { BsCurrencyEuro, BsIncognito } from "react-icons/bs";
import { FiMail, FiPhone, FiUser } from "react-icons/fi";
import {
  RiAdminFill,
  RiBarChartFill,
  RiBuilding4Line,
  RiCalendarEventFill,
  RiGroupFill,
  RiHistoryFill,
  RiLogoutBoxRLine,
  RiMapPin2Fill,
  RiMenu3Fill,
  RiNotification3Fill,
  RiServiceFill,
  RiSettings3Fill,
  RiUserAddFill,
  RiUserLine,
  RiGiftFill,
} from "react-icons/ri";
import {
  FileCog,
  List,
  ReceiptText,
  Blocks,
  Wallet,
  Package,
  Truck,
  PackageOpen,
  Scroll,
} from "lucide-react";
import useAccountInfo from "../../hooks/useAccountInfo";
import useFeature from "../../hooks/useFeature";
import { useScheduleStore } from "../../pages/Dashboard/Schedule/Stores/useScheduleStore";
import useUserPermissionsStore from "../../stores/useUserPermissionsStore";
import {
  Item,
  ItemsWrapper,
  LogoutButton,
  PagesGroupLabel,
  TopRow,
  Wrapper,
} from "./Sidebar.styled";
import { useMediaQuery } from "@mantine/hooks";

type SidebarPageListItem = {
  name: string;
  icon: JSX.Element;
  link: string;
  show: boolean;
  checkLinkStartsWith?: boolean;
  disabled?: boolean;
};

const Sidebar = ({ page }: { page: string }) => {
  const posthog = usePostHog();

  const { isOpen: sidebarOpen, toggle: setSidebarOpen } = useSidebarStore(
    (state) => state,
  );
  useLingui();

  const { selectSchedule } = useScheduleStore((state) => state);

  const { isFeatureEnabled } = useFeature();

  const accountInfo = useAccountInfo((state) => state.accountInfo);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { mutateAsync: loginAsAdmin } = api.admin.useLoginAsAdmin();

  const mainPagesList: SidebarPageListItem[] = [
    {
      name: t`Koledar`,
      icon: <RiCalendarEventFill />,
      link: "/dashboard/calendar",
      show: true,
    },
    {
      name: t`Pregled strank`,
      icon: <RiGroupFill />,
      link: "/dashboard/completed",
      show: permissions.view_customer_list,
    },
    {
      name: t`Analitika`,
      icon: <RiBarChartFill />,
      link: "/dashboard/analytics",
      show: permissions.view_analytics,
    },
    {
      name: t`Admin`,
      icon: <RiAdminFill />,
      link: "/dashboard/admin",
      show: ["admin", "salesman"].includes(accountInfo.systemRole),
    },
  ];

  const taxRegisterPagesList: SidebarPageListItem[] = isFeatureEnabled(
    "TAX_REGISTER",
  )
    ? [
        {
          name: t`Nov račun`,
          icon: <ReceiptText />,
          link: "/dashboard/tax-register/invoice/new",
          show: true,
        },
        {
          name: t`Arhiv računov`,
          icon: <List />,
          link: "/dashboard/tax-register/invoice",
          show: true,
        },
        // {
        //   name: t`Dobava`,
        //   icon: <Package />,
        //   link: "/dashboard/tax-register/delivery",
        //   show: true,
        // },
        {
          name: t`Blagajna`,
          icon: <Wallet />,
          link: "/dashboard/tax-register/cash-register",
          show: true,
          checkLinkStartsWith: true,
        },
        {
          name: t`Zaloga`,
          icon: <PackageOpen />,
          link: "/dashboard/product-stock",
          show: true,
          checkLinkStartsWith: true,
        },
        {
          name: t`Inventura`,
          icon: <Scroll />,
          link: "/dashboard/stock-taking",
          show: true,
          checkLinkStartsWith: true,
        },
        {
          name: t`Dobavnice`,
          icon: <Truck />,
          link: "/dashboard/delivery-orders",
          show: true,
          checkLinkStartsWith: true,
        },
        {
          name: t`Nastavitve`,
          icon: <FileCog />,
          link: "/dashboard/tax-register/settings/setup",
          show: true,
          checkLinkStartsWith: true,
        },
      ]
    : [];

  const settingsPagesList: SidebarPageListItem[] = [
    {
      name: t`Lokacija`,
      icon: <RiMapPin2Fill />,
      link: "/dashboard/locations",
      show: permissions.view_locations_page,
    },
    {
      name: t`Storitve`,
      icon: <RiServiceFill />,
      link: "/dashboard/services",
      show: permissions.view_services_page,
    },
    {
      name: t`Produkti`,
      icon: <Package />,
      link: "/dashboard/products",
      show: isFeatureEnabled("TAX_REGISTER"),
    },
    {
      name: t`Sredstva`,
      icon: <RiBuilding4Line />,
      link: "/dashboard/resource-groups",
      show: isFeatureEnabled("RESOURCES") && permissions.view_resources_page,
    },
    {
      name: t`Osebje`,
      icon: <RiUserAddFill />,
      link: "/dashboard/users",
      show: permissions.view_users_page,
    },

    {
      name: t`Urniki`,
      icon: <RiCalendarEventFill />,
      link: "/dashboard/schedule",
      show: permissions.view_availability,
    },
    {
      name: t`Splošne nastavitve`,
      icon: <RiSettings3Fill />,
      link: "/dashboard/settings",
      show: permissions.view_settings_page,
    },
    {
      name: t`Obvestila`,
      icon: <RiNotification3Fill />,
      link: "/dashboard/notifications",
      show: permissions.view_notifications_page && accountInfo?.onboardingDone,
    },
    {
      name: t`Zgodovina sprememb`,
      icon: <RiHistoryFill />,
      link: "/dashboard/change-log",
      show: isFeatureEnabled("CHANGELOG") && permissions.view_change_log_page,
    },
    {
      name: t`Spletna plačila`,
      icon: <BsCurrencyEuro />,
      link: "/dashboard/stripe",
      show:
        isFeatureEnabled("PAYMENTS") && permissions.view_online_payments_page,
    },
    {
      name: t`Darilni boni`,
      icon: <RiGiftFill />,
      link: "/dashboard/gift-card",
      show: isFeatureEnabled("GIFTCARD"), // && permissions.view_gift_card_page,
    },
    {
      name: t`Integracije`,
      icon: <Blocks />,
      link: "/dashboard/integrations",
      show:
        isFeatureEnabled("INTEGRATION_FACEBOOK_CONVERSION_API") && // TODO: better check if ANY feature is enabled
        permissions.view_integrations_page,
    },
  ];

  const mainPages = mainPagesList.map((item) => {
    if (!item.show) return;
    return (
      <Item
        key={item.link}
        $isOpen={sidebarOpen}
        selected={page === item.link}
        onClick={() => onSelect(item.link)}
        to={item.link}
      >
        <div className={"leftKnob"} />
        <div className={"content"}>
          <div className={"icon"}>{item.icon}</div>
          <Text
            variant="bodyEmphasized"
            className={"name"}
            c={page === item.link ? "#FFFFFF" : Color.SecondaryText}
          >
            {item.name}
          </Text>
        </div>
      </Item>
    );
  });

  const taxRegisterPages = taxRegisterPagesList.map((item) => {
    if (!item.show) return;

    const isSelected = item.checkLinkStartsWith
      ? page.startsWith(item.link)
      : page === item.link;

    return (
      <Item
        key={item.link}
        $isOpen={sidebarOpen}
        $isDisabled={item.disabled}
        selected={isSelected}
        onClick={item.disabled ? undefined : () => onSelect(item.link)}
        to={item.disabled ? "." : item.link}
        relative={item.disabled ? "path" : "route"}
      >
        <div className={"leftKnob"} />
        <div className={"content"}>
          <div className={"icon"}>{item.icon}</div>
          <Text
            variant="bodyEmphasized"
            className={"name"}
            c={
              item.disabled
                ? "var(--disable)"
                : isSelected
                  ? "#FFFFFF"
                  : Color.SecondaryText
            }
          >
            {item.name}
          </Text>
        </div>
      </Item>
    );
  });

  const settingsPages = settingsPagesList.map((item) => {
    if (!item.show) return;

    const isSelected = page.includes(item.link);

    return (
      <Item
        key={item.link}
        $isOpen={sidebarOpen}
        selected={isSelected}
        onClick={() => onSelect(item.link)}
        to={item.link}
      >
        <div className={"leftKnob"} />
        <div className={"content"}>
          <div className={"icon"}>{item.icon}</div>
          <Text
            variant={TextVariant.BodyEmphasized}
            className={"name"}
            c={isSelected ? "#FFFFFF" : Color.SecondaryText}
          >
            {item.name}
          </Text>
        </div>
      </Item>
    );
  });

  const isMobile = useMediaQuery("(max-width: 768px)") || false;

  useEffect(() => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  }, [isMobile]);

  function onSelect(link: string) {
    if (link === "/dashboard/schedule") {
      selectSchedule(null);
    }

    if (isMobile) {
      setSidebarOpen(false);
    }
  }

  const logout = () => {
    posthog?.reset();
    localStorage.removeItem("usr_token");

    // @ts-expect-error - correct
    window?.productFruits?.services?.destroy();
    window.location.reload();
  };

  const handleLoginAsAdmin = async () => {
    try {
      const response = await loginAsAdmin(accountInfo.superAdmin.clientId);

      localStorage.setItem("usr_token", response.token);

      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Wrapper isMobile={isMobile} isOpen={sidebarOpen}>
        <TopRow
          isOpen={sidebarOpen}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <img
            className={"logo"}
            src="/logo_full_green.svg"
            alt="Lime"
            style={{ userSelect: "none", maxWidth: "120px" }}
          />
          <div>
            <RiMenu3Fill style={{ fontSize: "25px", color: "#A0C5B5" }} />
          </div>
        </TopRow>
        <ItemsWrapper>{mainPages}</ItemsWrapper>

        {isFeatureEnabled("TAX_REGISTER") && (
          <>
            <PagesGroupLabel isOpen={sidebarOpen}>
              <Trans>Davčna blagajna</Trans>
            </PagesGroupLabel>
            <ItemsWrapper>{taxRegisterPages}</ItemsWrapper>
          </>
        )}

        <PagesGroupLabel isOpen={sidebarOpen}>
          <Trans>Nastavitve</Trans>
        </PagesGroupLabel>
        <ItemsWrapper>{settingsPages}</ItemsWrapper>
        <div style={{ flex: 1, padding: "10px 0" }}></div>

        <Popover
          position="right-end"
          withArrow
          shadow="md"
          withinPortal={true}
          offset={sidebarOpen ? 0 : -80}
        >
          <Popover.Target>
            <div
              style={{
                width: "fit-content",
                height: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                marginLeft: "10px",
                padding: "10px",
                cursor: "pointer",
                color: Color.SecondaryText,
              }}
            >
              <FiUser />
              <Text
                variant={TextVariant.BodyEmphasized}
                c={Color.SecondaryText}
                style={{
                  // fontSize: "0.875rem",
                  marginLeft: "12px",
                  opacity: sidebarOpen ? 1 : 0,
                  // fontWeight: 500,
                  transition: "all 0.5s cubic-bezier(0.19, 1, 0.22, 1)",
                  transform: "translateX(" + (sidebarOpen ? "0" : "10px") + ")",
                }}
              >
                <Trans>Uporabnik</Trans>
              </Text>
            </div>
          </Popover.Target>
          <Popover.Dropdown>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: ".9rem",
              }}
            >
              <span style={{ fontWeight: 500, marginBottom: "10px" }}>
                {accountInfo.businessName}
              </span>
              <span
                style={{
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <RiUserLine /> {accountInfo.userName} {accountInfo.userLastName}
              </span>
              {accountInfo.userEmail && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <FiMail /> {accountInfo.userEmail}
                </span>
              )}
              {accountInfo.userPhone && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <FiPhone /> {accountInfo.userPhone}
                </span>
              )}
            </div>
          </Popover.Dropdown>
        </Popover>

        {accountInfo.superAdmin ? (
          <LogoutButton
            isOpen={sidebarOpen}
            onClick={handleLoginAsAdmin}
            style={{ background: "#df3400", color: "white" }}
          >
            <BsIncognito
              style={{
                width: "20px",
                height: "20px",
                color: "white",
              }}
            />{" "}
            <Text
              c={"white"}
              className={"text"}
              variant={TextVariant.BodyEmphasized}
            >
              <Trans>Nazaj v admin račun</Trans>
            </Text>
          </LogoutButton>
        ) : (
          <LogoutButton isOpen={sidebarOpen} onClick={() => logout()}>
            <RiLogoutBoxRLine
              style={{
                width: "20px",
                height: "20px",
                color: Color.SecondaryText,
              }}
            />{" "}
            <Text
              c={Color.SecondaryText}
              className={"text"}
              variant={TextVariant.BodyEmphasized}
            >
              <Trans>Odjava</Trans>
            </Text>
          </LogoutButton>
        )}
        <Text
          variant={TextVariant.Caption}
          ta={"center"}
          c={Color.SecondaryText}
        >
          {import.meta.env.VITE_APP_VERSION}
        </Text>
      </Wrapper>
    </>
  );
};

export default Sidebar;
