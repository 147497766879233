import LimeDrawer from "@/Components/LimeDrawer/LimeDrawer";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Collapse,
  Flex,
  MultiSelect,
  NumberInput,
  Select,
  Switch,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useNotificationSettingModal } from "../hooks/useNotificationSettingModal";
import { AlertChip } from "@/Components/AlertChip/AlertChip";
import { TextVariant } from "@/types/text-variants";
import { TIME_UNITS } from "../constants/TimeUnits";
import { TRIGGERS } from "../constants/Triggers";
import classes from "./NotificationSettingModal.module.css";

export const NotificationSettingModal = ({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}) => {
  const {
    handleCreateNewSetting,
    newSettingForm,
    tags,
    handleTagClick,
    notificationContentRef,
    smsInfo,
    services,
    handleUpdateNewSetting,
  } = useNotificationSettingModal(onClose);

  return (
    <>
      <LimeDrawer
        onClose={onClose}
        opened={opened}
        title={t`Novo obvestilo`}
        buttons={{
          save: (
            <Button
              type={"submit"}
              onClick={() => {
                if (newSettingForm.validate().hasErrors) return;

                newSettingForm.values.notificationSettingId
                  ? handleUpdateNewSetting()
                  : handleCreateNewSetting();
              }}
              id="save-notification-setting"
            >
              <Trans>Shrani</Trans>
            </Button>
          ),
        }}
      >
        <form
          onSubmit={newSettingForm.onSubmit(handleCreateNewSetting)}
          className={classes.formWrapper}
        >
          <div className={classes.itemWrapper}>
            <Text variant={TextVariant.BodyEmphasized}>
              <Trans>Vrsta obvestila</Trans>
            </Text>
            <Select
              {...newSettingForm.getInputProps("notification_type", {
                type: "input",
              })}
              data={["SMS", "EMAIL"]}
              allowDeselect={false}
              id="notification_type"
            />
          </div>

          <div className={classes.itemWrapper}>
            <Text variant={TextVariant.BodyEmphasized}>
              <Trans>Čas pošiljanja obvestila</Trans>
            </Text>
            <Select
              {...newSettingForm.getInputProps("trigger", {
                type: "input",
              })}
              data={TRIGGERS().map((trigger) => ({
                label: trigger.label,
                value: trigger.trigger,
              }))}
              allowDeselect={false}
            />
          </div>

          <Collapse
            in={["cronBeforeAppointment", "cronAfterAppointment"].includes(
              newSettingForm.values.trigger,
            )}
          >
            <Flex gap={50}>
              <NumberInput
                {...newSettingForm.getInputProps("time_offset")}
                w={"50%"}
              />
              <Select
                data={TIME_UNITS().map((tu) => ({
                  value: tu.unit,
                  label: tu.label,
                }))}
                {...newSettingForm.getInputProps("unit")}
                w={"50%"}
              />
            </Flex>
          </Collapse>

          <Collapse in={newSettingForm.values.notification_type === "EMAIL"}>
            <div className={classes.itemWrapper}>
              <Text variant={TextVariant.BodyEmphasized}>
                <Trans>Zadeva</Trans>
              </Text>
              <TextInput
                {...newSettingForm.getInputProps("notification_subject")}
              />
            </div>
          </Collapse>

          <div className={classes.itemWrapper}>
            <Text variant={TextVariant.BodyEmphasized}>
              <Trans>Sporočilo</Trans>
            </Text>
            <Flex wrap={"wrap"} gap={5} py={"sm"}>
              {tags
                .filter((tag) => {
                  if (tag.value === "{rescheduleFromDateTime}") {
                    return newSettingForm.values.trigger === "onReschedule";
                  }
                  return true;
                })
                .map((tag, idx) => (
                  <Tooltip
                    key={idx}
                    openDelay={400}
                    label={t`Sporočilu bo dodalo ${tag.length} znakov`}
                  >
                    <Button
                      variant="light"
                      size="xs"
                      radius={"xl"}
                      leftSection={
                        <Text variant={TextVariant.CaptionEmphasized}>
                          {tag.length}
                        </Text>
                      }
                      onClick={() => handleTagClick(tag.value)}
                    >
                      {tag.label}
                    </Button>
                  </Tooltip>
                ))}
            </Flex>
            <Textarea
              ref={notificationContentRef}
              {...newSettingForm.getInputProps("notification_template")}
              minRows={3}
              autosize
              variant={"filled"}
              id="notification-template"
            />

            {newSettingForm.values.notification_type === "SMS" && (
              <>
                <Text variant={TextVariant.Caption} mt={"xs"}>
                  <Trans>Porabljenih znakov</Trans>:{" "}
                  {smsInfo.characterPerMessage - smsInfo.remaining} od{" "}
                  {smsInfo.characterPerMessage} <Trans>v</Trans>{" "}
                  {smsInfo.messages} <Trans>SMS sporočilih</Trans>
                </Text>

                {smsInfo.messages > 1 && (
                  <AlertChip
                    text={t`Sporočilo se bo razdelilo na več SMS sporočil.`}
                    variant={"error"}
                  />
                )}
              </>
            )}

            <Flex direction={"column"} gap={10}>
              <Switch
                label={t`Pošlji obvestilo za vse storitve?`}
                {...newSettingForm.getInputProps("all_services")}
                checked={newSettingForm.values.all_services}
              />
              <Collapse in={newSettingForm.values.all_services === false}>
                <MultiSelect
                  data={services.map(
                    (s: { name: string; serviceId: number }) => ({
                      label: s.name,
                      value: s.serviceId.toString(),
                    }),
                  )}
                  label={t`Izbrane storitve`}
                  {...newSettingForm.getInputProps("services")}
                />
              </Collapse>
            </Flex>
          </div>
        </form>
      </LimeDrawer>
    </>
  );
};
