import { LimeSwitch } from "@/Components/LimeSwitch";
import { LimeTextarea } from "@/Components/LimeTextarea";
import LocalePicker from "@/Components/LocalePicker";
import { UserAppointmentMessageList } from "@/Components/MessageScheduler";
import useFeature from "@/hooks/useFeature";
import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { cn, getLocalizedName } from "@/utils";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Loader,
  NumberInput,
  Select,
  Space,
  Switch,
  Table,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useClickOutside, useDebouncedCallback } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import dayjs from "dayjs";
import humanizeDuration from "humanize-duration";
import _ from "lodash";
import React, { useEffect } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import { numberAndCountryCodeToFullPhoneNumber } from "../../../Utilities";
import usePreferredLanguageStore from "../../../stores/usePreferredLanguageStore";
import LimePhoneInputField from "../../LimePhoneInputField";
import { apiGetCustomerSearch } from "../apiAppointment";
import { useAppointmentModalStore } from "../useAppointmentModalStore_";
import { NotificationTriggerSection } from "./NotificationTriggerSection";
import { MessageModal } from "./UserAppointmentMessages";
import { api } from "@/lib/api-client";
import { Spinner } from "@nextui-org/react";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { i18n } from "@lingui/core";

const Form = ({
  disableUnEditableFields,
  customerData,
  handleSubmitAppointmentForm,
  isNewAppointment,
  selectedServices,
  IS_GLOWUP_USER,
  IS_ILLUME_USER,
  notificationSchedule,
  notificationLog,
  dayObject,
}) => {
  useLingui();
  const customerSearchRef = useClickOutside(() => setSearchedCustomers([]));
  const [canSubmitForm, setCanSubmitForm] = React.useState(true);

  const { isFeatureEnabled } = useFeature();

  const { locations, selectedLocationId, calendarData } = useCalendarStore();

  const { locale } = i18n;

  const { appointmentData, setCurrentAppointmentGsm } =
    useAppointmentModalStore();

  const [lastSelectedInput, setLastSelectedInput] = React.useState(null);

  const { clientPreferredLanguage, userPreferredLanguage } =
    usePreferredLanguageStore((state) => state.preferredLanguage);

  const defaultLanguage =
    userPreferredLanguage || clientPreferredLanguage || "sl";

  const doesAnyServiceRequirePayment = selectedServices.some(
    (service) => service.formPaymentRequired,
  );
  const form = useForm({
    initialValues: {
      customerId: customerData?.customerId ?? null,
      name: customerData?.name ?? "",
      lastName: customerData?.lastName ?? "",
      email: customerData?.email ?? "",
      countryCode: customerData?.countryCode ?? "",
      gsm:
        numberAndCountryCodeToFullPhoneNumber(
          customerData?.gsm,
          customerData?.countryCode,
        ) ?? "",
      price: customerData?.price ?? 0.0,
      // birthday: customerData?.birthday
      //   ? dayjs(customerData?.birthday).toDate()
      //   : null,
      notes: customerData?.notes ?? "",
      comment: customerData?.comment ?? "",
      isRepeating: false,
      repeatType: "week",
      repeatInterval: 1,
      repeatDuration: 1,
      payOnlyFirstAppointment: false,
      sendNotifications: true,
      createPayment:
        doesAnyServiceRequirePayment &&
        calendarData.isPaymentDefaultCheckedInDashboard,
      createPaymentLocale: defaultLanguage,
      createPaymentChannel: "sms",
      createPaymentTimeoutMinutes: calendarData.defaultPaymentTimeoutMinutes,
      extraDetails: customerData?.extraDetails ?? [],
    },

    validate: {
      gsm: (value) => {
        if (!value) return null;

        const parsedGsm = parsePhoneNumber(value);
        if (!parsedGsm) {
          return t`Telefonska številka ni veljavna`;
        }

        if (!parsedGsm.isValid()) {
          return t`Telefonska številka ni veljavna`;
        }

        return null;
      },
    },
  });

  const {
    data: calculatedPaymentPriceData,
    isPending: isPaymentPricePending,
    processedErrorMessage: paymentPriceErrorMessage,
  } = api.appointments.useGetCalculatePaymentPrice(
    {
      appointmentParts: selectedServices.map((service) => ({
        ...service,
        serviceId: Number(service.serviceId),
        priceCents: service.price * 100,
        discountAmount: service.discountAmount
          ? service.discountAmount *
            (service.discountType == "amount" ? 100 : 1)
          : 0,
      })),
      repeatData: form.getValues().isRepeating
        ? {
            type: form.getValues().repeatType,
            interval: form.getValues().repeatInterval || 1,
            count: form.getValues().repeatDuration || 1,
          }
        : undefined,
      paymentDetails: {
        onlyFirstAppointment: form.getValues().payOnlyFirstAppointment,
      },
      startTime: dayObject?.format("YYYY-MM-DDTHH:mm"),
      locationId: selectedLocationId,
    },
    form.getValues().createPayment,
  );

  const [searchedCustomers, setSearchedCustomers] = React.useState([]);
  const [isSearchingCustomers, setIsSearchingCustomers] = React.useState(false);

  const notificationLogFormatted = notificationSchedule.map((nl) => {
    const timeMinutes = nl.notification_setting.time_offset;
    const timeMilliseconds = timeMinutes * 60 * 1000;

    let chronology = "";
    const { trigger } = nl;
    if (trigger === "cronBeforeAppointment") {
      chronology = t`pred terminom`;
    } else if (trigger === "cronAfterAppointment") {
      chronology = t`po terminu.`;
    }

    const time = humanizeDuration(timeMilliseconds, { language: "sl" });

    const text = `${nl.notification_setting.notification_type} ${time} ${chronology}`;
    return {
      text,
    };
  });

  useEffect(() => {
    if (customerData == null) {
      form.setValues({
        customerId: null,
        name: "",
        lastName: "",
        email: "",
        countryCode: "",
        gsm: "",
        price: 0.0,
        // birthday: null,
        notes: "",
        comment: "",
        isRepeating: false,
        repeatType: "week",
        repeatInterval: 1,
        repeatDuration: 1,
        sendNotifications: true,
        createPayment:
          doesAnyServiceRequirePayment &&
          calendarData.isPaymentDefaultCheckedInDashboard,
        createPaymentTimeoutMinutes: calendarData.defaultPaymentTimeoutMinutes,
        createPaymentLocale:
          userPreferredLanguage || clientPreferredLanguage || "sl",
        createPaymentChannel: "sms",
        extraDetails: [],
      });
      return;
    }

    if (customerData?.userAppointmentId) {
      form.setValues({
        customerId: customerData?.customerId,
        name: customerData?.name,
        lastName: customerData?.lastName,
        email: customerData?.email,
        countryCode: customerData?.countryCode,
        gsm:
          numberAndCountryCodeToFullPhoneNumber(
            customerData?.gsm,
            customerData?.countryCode,
          ) ?? null,
        price: customerData?.price ?? 0.0,
        // birthday: customerData?.birthday
        //   ? dayjs(customerData?.birthday).toDate()
        //   : null,
        notes: customerData?.notes,
        comment: customerData?.comment,
        isRepeating: false,
        repeatType: "week",
        repeatInterval: 1,
        repeatDuration: 1,
        extraDetails: customerData?.extraDetails ?? [],
      });
    }
  }, [customerData?.userAppointmentId]);

  useEffect(() => {
    setCurrentAppointmentGsm(form.values.gsm);
  }, [form.values.gsm]);

  useEffect(() => {
    if (form.values.customerId) return;

    if (
      !form.values.name &&
      !form.values.lastName &&
      !form.values.email &&
      !form.values.gsm
    )
      return setSearchedCustomers([]);

    handleSearchCustomers("");
  }, [
    form.values.name,
    form.values.lastName,
    form.values.email,
    form.values.gsm,
    form.values.customerId,
    setSearchedCustomers,
  ]);

  const handleSearchCustomers = useDebouncedCallback(async () => {
    const parsedGsm = form.values.gsm ? parsePhoneNumber(form.values.gsm) : "";

    setIsSearchingCustomers(true);
    const response = await apiGetCustomerSearch(
      form.values.name,
      form.values.lastName,
      form.values.email,
      parsedGsm?.nationalNumber ?? "",
    ).catch((e) => {});
    setIsSearchingCustomers(false);

    if (response == null || response.status !== 200) {
      setSearchedCustomers([]);
      return;
    }

    setSearchedCustomers(
      response.data.map((customer) => ({
        ...customer,
        value: customer.name,
      })),
    );
  }, 250);

  let totalPrice = 0;
  selectedServices.forEach((service) => {
    if (_.isNumber(service.price)) {
      totalPrice += service.price;
    }
  });

  return (
    <Wrapper>
      <Box>
        <form
          id={"appointment-form"}
          onSubmit={(e) => {
            e.preventDefault();
            if (!canSubmitForm) return;
            setCanSubmitForm(false);

            if (isNewAppointment && !form.values.name) {
              modals.openConfirmModal({
                title: t`Želite ustvariti termin brez osebe?`,
                children: (
                  <Text size="sm">
                    <Trans>
                      Generirana bo nova stranka z imenom &quot;Brez Imena&quot;
                      in naključnim priimkom. Želite nadaljevati?
                    </Trans>
                  </Text>
                ),
                labels: { confirm: t`Potrdi`, cancel: t`Prekliči` },
                onConfirm: () => {
                  setCanSubmitForm(true);

                  if (!form.validate()) {
                    return;
                  }

                  handleSubmitAppointmentForm(form.values);
                },
                onCancel: () => {
                  setCanSubmitForm(true);
                },
                withinPortal: true,
                withCloseButton: false,
              });
            } else {
              if (!form.validate()) {
                return;
              }

              handleSubmitAppointmentForm(form.values);
            }
            setCanSubmitForm(true);
          }}
        >
          <Flex direction={"column"} gap={"1rem"}>
            <Flex
              direction="row"
              justify="space-between"
              gap={"1rem"}
              style={{ height: "65px", position: "relative" }}
            >
              <TextInput
                id={"name"}
                data-identifier={"name"}
                transitionProps={{
                  transition: "pop-top-left",
                  duration: 80,
                  timingFunction: "ease",
                }}
                {...{
                  ...form.getInputProps("name"),
                  onChange: (e) => {
                    form.setFieldValue("name", e.currentTarget.value);
                    form.setFieldValue("customerId", null);

                    setLastSelectedInput("name");
                  },
                }}
                rightSection={
                  isSearchingCustomers ? <Loader size={16} /> : null
                }
                label={t`Ime`}
                style={{ flex: 1 }}
                variant={"filled"}
                disabled={disableUnEditableFields}
                autoComplete="do-not-autofill"
              />
              <TextInput
                id={"lastName"}
                label={t`Priimek`}
                data-identifier={"lastName"}
                {...{
                  ...form.getInputProps("lastName"),
                  onChange: (e) => {
                    form.setFieldValue("lastName", e.currentTarget.value);
                    form.setFieldValue("customerId", null);

                    setLastSelectedInput("lastName");
                  },
                }}
                style={{ flex: 1 }}
                variant={"filled"}
                disabled={disableUnEditableFields}
                autoComplete="do-not-autofill"
              />

              {(lastSelectedInput === "name" ||
                lastSelectedInput === "lastName") && (
                <SearchedCustomers
                  customerSearchRef={customerSearchRef}
                  IS_GLOWUP_USER={IS_GLOWUP_USER || IS_ILLUME_USER}
                  form={form}
                  searchedCustomers={searchedCustomers}
                  setSearchedCustomers={setSearchedCustomers}
                />
              )}
            </Flex>
            {((IS_ILLUME_USER && isNewAppointment) || !IS_ILLUME_USER) && (
              <Flex justify="space-between" style={{ position: "relative" }}>
                <TextInput
                  id={"email"}
                  label={t`E-pošta`}
                  data-identifier={"email"}
                  {...{
                    ...form.getInputProps("email"),
                    onChange: (e) => {
                      form.setFieldValue("email", e.currentTarget.value);
                      form.setFieldValue("customerId", null);

                      setLastSelectedInput("email");
                    },
                  }}
                  variant={"filled"}
                  disabled={disableUnEditableFields}
                  display={IS_GLOWUP_USER ? "none" : "block"}
                  autoComplete="do-not-autofill"
                  w={"100%"}
                />

                {lastSelectedInput === "email" && (
                  <SearchedCustomers
                    customerSearchRef={customerSearchRef}
                    IS_GLOWUP_USER={IS_GLOWUP_USER || IS_ILLUME_USER}
                    form={form}
                    searchedCustomers={searchedCustomers}
                    setSearchedCustomers={setSearchedCustomers}
                  />
                )}
              </Flex>
            )}

            {((IS_ILLUME_USER && isNewAppointment) || !IS_ILLUME_USER) && (
              <div
                style={{
                  display: IS_GLOWUP_USER ? "none" : "block",
                  position: "relative",
                }}
              >
                <Text size={"sm"} fw={500} c={"#212529"}>
                  <Trans>Telefonska številka</Trans>
                </Text>

                {!disableUnEditableFields && (
                  <LimePhoneInputField
                    id={"tel"}
                    data-identifier={"gsm"}
                    {...{
                      ...form.getInputProps("gsm"),
                      onChange: (e) => {
                        form.setFieldValue("gsm", e);
                        form.setFieldValue("customerId", null);

                        setLastSelectedInput("gsm");
                      },
                    }}
                    filled
                    disabled={disableUnEditableFields}
                    form
                    autoComplete="do-not-autofill"
                    defaultCountry={
                      locations?.find(
                        (l) => l.locationId === selectedLocationId,
                      )?.country || "SI"
                    }
                  />
                )}

                {disableUnEditableFields && (
                  <a
                    href={`tel:${form.values.gsm}`}
                    style={{
                      display: "block",
                      padding: "7px 5px",
                      border: `1px solid ${Color.Border}`,
                      borderRadius: "4px",
                    }}
                    className={
                      !isValidPhoneNumber(form.values.gsm) ? "disabled" : ""
                    }
                  >
                    <LimePhoneInputField
                      value={form.values.gsm}
                      filled
                      data-identifier={"gsm"}
                      disabled={disableUnEditableFields}
                      form
                      autoComplete="do-not-autofill"
                      defaultCountry={
                        locations?.find(
                          (l) => l.locationId === selectedLocationId,
                        )?.country || "SI"
                      }
                      disablePointerEvents
                    />
                  </a>
                )}

                {lastSelectedInput === "gsm" && (
                  <SearchedCustomers
                    customerSearchRef={customerSearchRef}
                    IS_GLOWUP_USER={IS_GLOWUP_USER || IS_ILLUME_USER}
                    form={form}
                    searchedCustomers={searchedCustomers}
                    setSearchedCustomers={setSearchedCustomers}
                  />
                )}
              </div>
            )}

            {/* <DateInput
              label={t`Datum rojstva`}
              {...form.getInputProps("birthday")}
              valueFormat="D.M.YYYY"
              disabled={disableUnEditableFields}
            ></DateInput> */}

            {form.values.notes && (
              <LimeTextarea
                label={t`Komentar stranke`}
                {...form.getInputProps("notes")}
                variant={"filled"}
                autosize
                disabled
              />
            )}

            <NumberInput
              id={"price"}
              label={t`Končna cena`}
              placeholder={"0"}
              value={totalPrice}
              variant={"filled"}
              disabled={true}
              autoComplete="do-not-autofill"
            />

            <Textarea
              label={t`Komentar termina`}
              placeholder={t`Komentar termina`}
              {...form.getInputProps("comment")}
              variant={"filled"}
              autosize
              minRows={4}
            />
            {form.values.extraDetails &&
              Object.entries(form.values.extraDetails).length > 0 && (
                <Flex direction={"column"}>
                  <Text variant={TextVariant.BodyEmphasized}>
                    <Trans>Dodatne informacije</Trans>
                  </Text>
                  <Table
                    withRowBorders={false}
                    bg={"#f8f8f8"}
                    styles={{ table: { borderRadius: "4px" } }}
                  >
                    {form.values.extraDetails.map((extraDetail, index) => {
                      const inputElement = (() => {
                        switch (extraDetail.type) {
                          case "multi-select":
                          case "checkbox":
                            return (
                              <Text>
                                {extraDetail.value
                                  .map(
                                    (v) =>
                                      v.defaultLanguage || Object.values(v)[0],
                                  )
                                  .join(", ")}
                              </Text>
                            );
                          case "radio":
                          case "select":
                            return (
                              <Text>
                                {extraDetail.value.defaultLanguage ||
                                  Object.values(extraDetail.value)[0]}
                              </Text>
                            );
                          case "date":
                            return (
                              <Text>
                                {dayjs(extraDetail.value, "MM-DD-YYYYTHH:mm")
                                  .toDate()
                                  .toLocaleString(locale, {
                                    day: "2-digit",
                                    month: "numeric",
                                    year: "numeric",
                                  })}
                              </Text>
                            );
                          case "time":
                            return (
                              <Text>
                                {dayjs(extraDetail.value, "MM-DD-YYYYTHH:mm")
                                  .toDate()
                                  .toLocaleString(locale, {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                              </Text>
                            );
                          default:
                            return <Text>{extraDetail.value}</Text>;
                        }
                      })();

                      return (
                        <Table.Tr>
                          <Table.Td pt={2} pb={2}>
                            <Text variant={TextVariant.BodyEmphasized}>
                              {getLocalizedName(
                                Object.entries(extraDetail.label).map(
                                  ([lang, value]) => ({
                                    language: lang,
                                    name: value,
                                  }),
                                ),
                              )}
                            </Text>
                          </Table.Td>
                          <Table.Td pt={0} pb={0}>
                            {inputElement}
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table>
                </Flex>
              )}

            {isNewAppointment ||
            (appointmentData?.isPreScheduled && !customerData) ? (
              <>
                <Switch
                  label={t`Pošlji obvestila?`}
                  {...form.getInputProps("sendNotifications", {
                    type: "checkbox",
                  })}
                />
              </>
            ) : (
              ""
            )}

            {isNewAppointment && isFeatureEnabled("PAYMENTS") && (
              <div
                className={cn("rounded-md transition-all", {
                  "bg-stone-100 p-2": form.values.createPayment,
                })}
              >
                <Tooltip label={t`Potrebno vpisati gsm ali email`} withArrow>
                  <div>
                    <Switch
                      label={t`Ustvari plačilo in pošlji stranki?`}
                      {...form.getInputProps("createPayment", {
                        type: "checkbox",
                      })}
                    />
                  </div>
                </Tooltip>

                <Collapse in={form.values.createPayment}>
                  <Box>
                    <div className="flex items-center gap-2 py-2">
                      <p
                        className={cn("mt-3 transition-colors", {
                          "text-gray-500": isPaymentPricePending,
                        })}
                      >
                        Za plačati{" "}
                        {!paymentPriceErrorMessage &&
                          calculatedPaymentPriceData?.formattedPrice}
                      </p>
                      {isPaymentPricePending && <Spinner size="sm" />}
                      <ErrorCard
                        className="flex-1"
                        isCompact
                        message={paymentPriceErrorMessage}
                      />
                    </div>

                    <div className="mb-2">
                      <NumberInput
                        label={t`Čas za plačilo (min)`}
                        min={1}
                        {...form.getInputProps("createPaymentTimeoutMinutes")}
                      />
                    </div>

                    <Flex justify={"space-between"} align={"center"} gap={10}>
                      <LocalePicker
                        onChange={(val) => {
                          form.setFieldValue("createPaymentLocale", val);
                        }}
                        value={form.values.createPaymentLocale}
                        isOnForm
                      />

                      <Select
                        {...form.getInputProps("createPaymentChannel")}
                        data={[
                          {
                            value: "sms",
                            label: t`SMS`,
                          },
                          {
                            value: "email",
                            label: t`Email`,
                          },
                        ]}
                        allowDeselect={false}
                        className="flex-1"
                      />
                    </Flex>
                  </Box>
                </Collapse>
              </div>
            )}

            {isNewAppointment && (
              <div
                className={cn("rounded-md transition-all", {
                  "bg-stone-100 p-2": form.values.isRepeating,
                })}
              >
                <Switch
                  label={t`Ponavljajoč termin`}
                  {...form.getInputProps("isRepeating", {
                    type: "checkbox",
                  })}
                />
                {form.values.isRepeating && (
                  <Flex direction={"column"} gap={"1rem"} mt={"10px"}>
                    <Flex
                      gap={"1rem"}
                      align={"center"}
                      justify={"space-between"}
                    >
                      <Text style={{ whiteSpace: "pre" }}>
                        <Trans>Ponovi vsak</Trans>
                      </Text>
                      <NumberInput
                        placeholder={"1"}
                        {...form.getInputProps("repeatInterval")}
                        min={1}
                      />
                      <Select
                        data={[
                          { label: t`Dan`, value: "day" },
                          { label: t`Teden`, value: "week" },
                          { label: t`Mesec`, value: "month" },
                        ]}
                        {...form.getInputProps("repeatType")}
                        defaultValue={"week"}
                      />
                    </Flex>
                    <NumberInput
                      label={t`Število ponavljanj`}
                      placeholder={"1"}
                      {...form.getInputProps("repeatDuration")}
                      min={1}
                    />
                    {form.values.createPayment && (
                      <LimeSwitch
                        label={t`Plačaj samo prvi termin`}
                        {...form.getInputProps("payOnlyFirstAppointment", {
                          type: "checkbox",
                        })}
                      />
                    )}
                  </Flex>
                )}
              </div>
            )}

            {customerData?.customFields != null &&
              customerData?.customFields?.length > 0 && (
                <Box
                  bg={"#F7F7F7"}
                  p={"4px 8px"}
                  style={{ borderRadius: "8px" }}
                >
                  <Text fw={500} size={"sm"}>
                    <Trans>Polja po meri</Trans>
                  </Text>
                  <Flex direction={"column"} gap={"2px"}>
                    {customerData?.customFields.map((field, index) => {
                      let value = field.value;
                      if (dayjs(field.value, "YYYY-MM-DD").isValid()) {
                        value = dayjs(field.value, "YYYY-MM-DD").format(
                          "DD.MM.YYYY",
                        );
                      }

                      return (
                        <Flex key={field.field} gap={"5px"}>
                          <Text size={"sm"} fw={500}>
                            {field.field}:{" "}
                          </Text>
                          <Text size={"sm"}>{value}</Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Box>
              )}

            {!isNewAppointment && (
              <>
                <Flex direction={"column"}>
                  {notificationLogFormatted.length ? (
                    <>
                      <Text variant={TextVariant.BodyEmphasized}>
                        <Trans>Obvestila</Trans>
                      </Text>
                      {notificationLogFormatted.map((notification, index) => {
                        return (
                          <Flex
                            key={index}
                            justify={"space-between"}
                            align={"center"}
                          >
                            <Text variant={TextVariant.Body}>
                              {notification.text}
                            </Text>
                            <NotificationColor
                              color={"orange"}
                            ></NotificationColor>
                          </Flex>
                        );
                      })}
                      <Space h={10}></Space>{" "}
                    </>
                  ) : null}

                  {notificationLog.length ? (
                    <>
                      <Text variant={TextVariant.BodyEmphasized}>
                        <Trans>Poslana</Trans>
                      </Text>

                      <Box ml={5}>
                        <NotificationTriggerSection
                          notificationLog={notificationLog}
                          triggers={["onConfirmation"]}
                          label={t`Ob potrditvi`}
                        ></NotificationTriggerSection>

                        <NotificationTriggerSection
                          notificationLog={notificationLog}
                          triggers={["onReschedule"]}
                          label={t`Ob spremembi termina`}
                        ></NotificationTriggerSection>

                        <NotificationTriggerSection
                          notificationLog={notificationLog}
                          triggers={["onCancelation"]}
                          label={t`Ob odpovedi`}
                        ></NotificationTriggerSection>

                        <NotificationTriggerSection
                          notificationLog={notificationLog}
                          triggers={[
                            "cronBeforeAppointment",
                            "cronAfterAppointment",
                          ]}
                          label={t`Pred/po terminu`}
                        ></NotificationTriggerSection>
                      </Box>
                    </>
                  ) : null}
                  {isFeatureEnabled("MESSAGE_SCHEDULE") &&
                  !isNewAppointment &&
                  customerData?.userAppointmentId ? (
                    <Flex direction={"column"}>
                      <Flex align={"center"} gap={10}>
                        <Text variant={TextVariant.BodyEmphasized}>
                          <Trans>Sporočila</Trans>
                        </Text>
                        <MessageModal
                          userAppointmentId={customerData.userAppointmentId}
                        ></MessageModal>
                      </Flex>

                      <Flex direction={"column"}>
                        <UserAppointmentMessageList
                          userAppointmentId={customerData.userAppointmentId}
                        ></UserAppointmentMessageList>
                      </Flex>
                    </Flex>
                  ) : null}
                </Flex>
              </>
            )}
          </Flex>
          <Button
            id={"appointment-form-submit"}
            type={"submit"}
            style={{
              display: "none",
            }}
          ></Button>
        </form>
      </Box>
    </Wrapper>
  );
};

const SearchedCustomers = ({
  searchedCustomers,
  setSearchedCustomers,
  form,
  customerSearchRef,
  IS_GLOWUP_USER,
}) => {
  return (
    <SearchedCustomersWrapper ref={customerSearchRef}>
      {searchedCustomers.map((customer) => (
        <CustomerSearchEntry
          key={customer.customerId}
          onClick={() => {
            setSearchedCustomers([]);
            form.setFieldValue("customerId", customer.customerId);
            form.setFieldValue("name", customer.name || "");
            form.setFieldValue("lastName", customer.lastName || "");
            form.setFieldValue("email", customer.email || "");
            form.setFieldValue(
              "gsm",
              numberAndCountryCodeToFullPhoneNumber(
                customer.gsm,
                customer.countryCode,
              ),
            );
            form.setFieldValue("countryCode", customer.countryCode);
            // form.setFieldValue(
            //   "birthday",
            //   customer.birthday ? dayjs(customer.birthday).toDate() : null
            // );
            form.setFieldValue("notes", customer.notes);
          }}
        >
          <Flex align={"flex-start"} gap={"sm"}>
            <div style={{ flex: 1 }}>
              <p className={"name"}>
                {customer.name} {customer.lastName}
              </p>
              {customer.email && (
                <p className={"email-gsm"}>
                  {IS_GLOWUP_USER ? "********" : customer.email}
                </p>
              )}
              {customer.gsm && (
                <p className={"email-gsm"}>
                  {IS_GLOWUP_USER ? "********" : customer.gsm}
                </p>
              )}
            </div>
            {customer.notes && (
              <div
                style={{
                  flex: 1,
                }}
              >
                <Text variant={TextVariant.Caption} c={"gray"}>
                  {customer.notes.length > 50
                    ? `${customer.notes.substring(0, 50)}...`
                    : customer.notes}
                </Text>
              </div>
            )}
          </Flex>
          <Divider w={"100%"} h={"2px"} mt={"sm"} />
        </CustomerSearchEntry>
      ))}
    </SearchedCustomersWrapper>
  );
};

const NotificationColor = styled.div`
  background: ${(props) => props.color};

  height: 15px;
  aspect-ratio: 1/1;

  border-radius: 50%;
`;

const Wrapper = styled.div`
  .mantine-Input-input:disabled,
  .PhoneInputInput:disabled,
  .PhoneInput--disabled .PhoneInputCountry {
    background-color: transparent !important;
    padding-left: 2px;
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
  }
`;

const SearchedCustomersWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
`;

const CustomerSearchEntry = styled.div`
  padding: 0.5rem;
  padding-bottom: 0;

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }

  .name {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .email-gsm {
    font-size: 0.7rem;
  }
`;

export default Form;
