import { GetCustomers } from "@/server-types";
import { api } from "@/lib/api-client";
import { cn } from "@/utils";
import { motion, AnimatePresence } from "framer-motion";
import { Button, Divider, Skeleton } from "@nextui-org/react";
import { t, Trans } from "@lingui/macro";
import { Ellipsis } from "lucide-react";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { ExtraMenuButtons } from "./POS/ExtraMenuModal";
import { CategoryServicesDrawerProps } from "./POS/CategoryServicesDrawer";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { POSForm } from "./POS/POS";
import { UseFormReturnType } from "@mantine/form";
import useFeature from "@/hooks/useFeature";

export type BottomActionsProps = {
  bottomActionsRef: React.RefObject<HTMLDivElement>;
  handleCategoryClick: (
    categoryId?: number,
    categoryType?: CategoryServicesDrawerProps["preSelectedCategoryType"],
  ) => void;
  categories?: {
    tagId: number;
    localizedName: string;
    color: string | null;
    type: "service" | "product";
  }[];
  isArticleSelected: boolean;
  form: UseFormReturnType<POSForm>;
  selectedCompanyId?: number;
  customerData?: GetCustomers["response"];
  canCreateInvoice: boolean;
  isCreatingInvoice: boolean;
  totalDiscountedInvoicePriceCents: number | undefined;
  totalInvoicePriceFormatted: string;
  totalDiscountedInvoicePriceFormatted: string | undefined;

  handleDeleteArticle: () => void;
  handleChangeArticleQuantity: (shouldIncrease: boolean) => void;
  handleDiscountArticle: () => void;
  handleOpenExtraMenu: () => void;
  handleAddDiscountToInvoice: () => void;
  handlePickCustomer: () => void;
  handleSplitPayment: () => void;
  onGiftCardsClick: () => void;

  isPendingCategories: boolean;
  isPendingServices: boolean;

  postInvoiceErrorMessage: string | null;
};

export const BottomActions = ({
  bottomActionsRef,

  handleCategoryClick,
  categories,
  isArticleSelected,
  selectedCompanyId,
  customerData,
  canCreateInvoice,
  isCreatingInvoice,

  form,

  totalDiscountedInvoicePriceCents,
  totalInvoicePriceFormatted,
  totalDiscountedInvoicePriceFormatted,

  handleDeleteArticle,
  handleChangeArticleQuantity,
  handleDiscountArticle,
  handleOpenExtraMenu,
  handleAddDiscountToInvoice,
  handlePickCustomer,
  handleSplitPayment,
  onGiftCardsClick,

  isPendingCategories,
  isPendingServices,

  postInvoiceErrorMessage,
}: BottomActionsProps) => {
  const { isFeatureEnabled } = useFeature();

  const { data: companyData } = api.company.getCompanies({
    companyId: selectedCompanyId,
  });

  const customerName = (() => {
    if (customerData != null) {
      const customer = customerData[0];

      if (!customer) {
        return undefined;
      }

      return `${customer.name} ${customer.lastName}`;
    }

    if (companyData != null) {
      return companyData[0]?.companyName;
    }
  })();

  const { isPrinting } = usePosPrinterStore((state) => state);

  return (
    <div
      ref={bottomActionsRef}
      className={cn(
        "ease-[cubic-bezier(0.19, 1, 0.22, 1)] duration-[0.35s] min-h-20 w-full bg-background transition-[width]",
        "lg:relative lg:block lg:h-full lg:flex-1", // desktop
        // {
        //   "md:w-[calc(100%-270px)]": isSidebarOpen,
        //   "md:w-[calc(100%-60px)]": !isSidebarOpen,
        // },
      )}
    >
      <AnimatePresence>
        {isArticleSelected && (
          <motion.div
            className="flex gap-1 p-2"
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: 5,
              transition: {
                type: "spring",
                bounce: 0.4,
                stiffness: 200,
                damping: 20,
              },
            }}
            transition={{
              type: "spring",
              bounce: 0.6,
              stiffness: 250,
            }}
          >
            <Button
              variant="bordered"
              className="h-8 w-full lg:h-12"
              onPress={handleDeleteArticle}
            >
              <Trans>Izbriši</Trans>
            </Button>
            <Button
              variant="bordered"
              className="h-8 w-8 lg:h-12"
              onPress={() => handleChangeArticleQuantity(false)}
            >
              -
            </Button>
            <Button
              variant="bordered"
              className="h-8 w-8 lg:h-12"
              onPress={() => handleChangeArticleQuantity(true)}
            >
              +
            </Button>
            <Button
              variant="bordered"
              className="h-8 w-full lg:h-12"
              onPress={handleDiscountArticle}
            >
              <Trans>Popust</Trans>
            </Button>
          </motion.div>
        )}
      </AnimatePresence>

      {customerName && (
        <p className="mb-2 px-4 text-sm">
          <Trans>Stranka</Trans>: {customerName}
        </p>
      )}

      <Divider />

      <div className="flex justify-between px-4 pb-2 pt-5 md:px-8">
        <p className="font-medium">
          <Trans>Skupaj</Trans>:
        </p>
        <div className="flex items-center gap-2">
          <p
            className={cn("text-xl font-bold", {
              "text-md text-gray-500 line-through":
                totalDiscountedInvoicePriceCents != null,
            })}
          >
            {totalInvoicePriceFormatted}
          </p>
          {totalDiscountedInvoicePriceFormatted != null && (
            <p className="text-xl font-bold">
              {totalDiscountedInvoicePriceFormatted}
            </p>
          )}
        </div>
      </div>

      <div className="px-4 md:px-8">
        <LimeSwitch
          title={t`Pošlji račun prek e-pošte`}
          description={t`Fizična stranka mora biti dodeljena na račun`}
          isCompact
          {...form.getInputProps("sendReceiptViaEmail", {
            type: "checkbox",
          })}
        />
      </div>

      <div className="grid w-full grid-cols-3 gap-1 px-4 py-4 md:px-8 lg:hidden">
        <ActionCategoryBlock
          name={t`Vsi artikli`}
          onClick={
            isPendingCategories || isPendingServices
              ? undefined
              : () => handleCategoryClick(undefined, "all")
          }
          color={null}
        />
        {isFeatureEnabled("GIFTCARD") ? (
          <ActionCategoryBlock
            name={t`Ustvari darilni bon`}
            onClick={
              isPendingCategories || isPendingServices
                ? undefined
                : () => onGiftCardsClick()
            }
            color={null}
          />
        ) : undefined}
        {!isPendingCategories &&
          !isPendingServices &&
          categories
            ?.slice(0, 4)
            .map((category) => (
              <ActionCategoryBlock
                key={`${category.type}-${category.tagId}`}
                name={category.localizedName}
                onClick={() =>
                  handleCategoryClick(category.tagId, category.type)
                }
                color={category.color}
              />
            ))}

        {(isPendingCategories || isPendingServices) &&
          Array.from({ length: 4 }).map((_, index) => (
            <Skeleton
              className="h-12 w-full rounded-xl"
              key={`cat-skel-${index}`}
            />
          ))}
      </div>

      {postInvoiceErrorMessage && (
        <>
          <div className="px-4 py-4 md:px-8">
            <ErrorCard message={postInvoiceErrorMessage} />
          </div>

          <Divider />
        </>
      )}

      <div
        className={cn("flex gap-2 px-4 py-2", "md:px-8 lg:grid lg:grid-cols-3")}
      >
        <Button
          color="default"
          className="h-12 p-0 lg:hidden"
          isIconOnly
          onClick={handleOpenExtraMenu}
        >
          <Ellipsis />
        </Button>
        <Button
          className="h-12 w-full lg:h-16"
          type="submit"
          isDisabled={!canCreateInvoice}
          isLoading={isCreatingInvoice || isPrinting}
          value={"card"}
          color="primary"
        >
          <Trans>Kartica</Trans>
        </Button>
        <Button
          className="h-12 w-full lg:h-16"
          type="submit"
          isDisabled={!canCreateInvoice}
          isLoading={isCreatingInvoice || isPrinting}
          value={"cash"}
          color="primary"
        >
          <Trans>Gotovina</Trans>
        </Button>
        <ExtraMenuButtons
          className="hidden lg:block lg:h-16"
          canCreateInvoice={canCreateInvoice}
          handleAddDiscountToInvoice={handleAddDiscountToInvoice}
          handlePickCustomer={handlePickCustomer}
          handleSplitPayment={handleSplitPayment}
        />
      </div>
    </div>
  );
};

const ActionCategoryBlock = ({
  name,
  onClick,
  color,
}: {
  name: string;
  onClick?: () => void;
  color: string | null;
}) => {
  return (
    <Button
      className={cn(
        "h-12 w-full bg-slate-200 px-4 text-xs font-medium leading-3",
      )}
      onPress={onClick}
      isDisabled={onClick == null}
      style={{
        backgroundColor: color || undefined,
        textWrap: "wrap",
      }}
    >
      <p>{name}</p>
    </Button>
  );
};
