import { t } from "@lingui/macro";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import { ActionIcon, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { RiCalendar2Fill, RiTimeFill } from "react-icons/ri";
import styled from "styled-components";

const DateTimeSection = ({
  date,
  startTime,
  endTime,
  handleDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  canChangeEndTime,
  isPreScheduled,
}) => {
  const fromTimeRef = React.useRef(null);
  const toTimeRef = React.useRef(null);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (fromTimeRef.current) {
      fromTimeRef.current.value = startTime;
    }

    if (toTimeRef.current) {
      toTimeRef.current.value = endTime;
    }
  }, [startTime, endTime]);

  const pickerControl = (ref) =>
    !isMobile ? (
      <ActionIcon
        variant="subtle"
        color="gray"
        onClick={() => ref.current?.showPicker()}
      >
        <RiTimeFill style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    ) : null;

  return (
    <Wrapper>
      <div className={"date"}>
        <DatePickerInput
          icon={<RiCalendar2Fill />}
          label={t`Datum`}
          value={dayjs(date).toDate()}
          onChange={handleDateChange}
          dropdownType={"popover"}
          variant={"filled"}
          valueFormat={"DD. MM. YYYY"}
          disabled={!!isPreScheduled}
          getDayProps={(day) => {
            // Highlight today's date only if it's not the selected date
            if (
              day.getDate() === new Date().getDate() &&
              dayjs(date).toDate().getDate() !== new Date().getDate()
            ) {
              return { bg: "red", c: "white", style: { borderRadius: "50%" } };
            }
          }}
        />
      </div>
      <div className={"time"}>
        <TimeInput
          ref={fromTimeRef}
          data-identifier="timeFromInput"
          label={t`Čas od`}
          variant={"filled"}
          defaultValue={startTime}
          onChange={(e) => {
            handleStartTimeChange(e.currentTarget.value);
          }}
          disabled={!!isPreScheduled}
          rightSection={pickerControl(fromTimeRef)}
        />

        <TimeInput
          ref={toTimeRef}
          label={t`Čas do`}
          variant={"filled"}
          defaultValue={endTime ?? 0}
          onChange={(e) => {
            handleEndTimeChange(e.currentTarget.value);
          }}
          disabled={true}
          rightSection={pickerControl(toTimeRef)}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eaebed;

  .date {
    flex: 1;
    white-space: pre;
  }

  .time {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;

    div {
      flex: 1;
    }

    @media (max-width: 768px) {
      input {
        padding-left: calc(2.25rem / 5);
        padding-right: 1.75rem;
      }
    }
  }
`;

export default DateTimeSection;
