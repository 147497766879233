import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimePhoneInput } from "@/Components/NextBase/LimePhoneInput";
import { LimeSingleSelect } from "@/Components/NextBase/LimeSelect";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { api } from "@/lib/api-client";
import { Language } from "@/server-types";
import { Color } from "@/types/colors";
import { numberAndCountryCodeToFullPhoneNumber } from "@/Utilities";
import { cn } from "@/utils";
import { t, Trans } from "@lingui/macro";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  useDisclosure,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Input,
  Spinner,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  RadioProps,
} from "@nextui-org/react";
import { AxiosError } from "axios";
import { Building, Plus, Search, User } from "lucide-react";
import { FormValidateInput } from "node_modules/@mantine/form/lib/types";
import { useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";

type EntityType = "physical" | "legal";

export const CustomerModal = ({
  isOpen,
  handleClose,
  preSelectedCustomerId,
}: {
  isOpen: boolean;
  handleClose: (data?: { customerId?: number; companyId?: number }) => void;
  preSelectedCustomerId?: number;
}) => {
  const {
    isOpen: isCreateCustomerModalOpen,
    onOpen: onOpenCreateCustomerModal,
    onClose: onCloseCreateCustomerModal,
  } = useDisclosure();

  const [selectedEntityType, setSelectedEntityType] =
    useState<EntityType>("physical");

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 200);

  const [selectedEntityId, setSelectedEntityId] = useState<number | null>(null);

  const { data: searchedCustomers, isFetching: isSearchingCustomers } =
    api.customer.getCustomers({
      searchQuery:
        selectedEntityType === "physical" ? debouncedSearchQuery : undefined,
    });

  const { data: searchedCompanies, isFetching: isSearchingCompanies } =
    api.company.getCompanies({
      searchQuery:
        selectedEntityType === "legal" ? debouncedSearchQuery : undefined,
    });

  useEffect(() => {
    if (isOpen) {
      setSelectedEntityId(preSelectedCustomerId ?? null);
      setSearchQuery("");
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={handleClose}
        classNames={{
          closeButton: "m-2",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <Trans>Stranka</Trans>
              </ModalHeader>
              <ModalBody className="px-0">
                <div className="flex gap-2 px-4">
                  <Input
                    endContent={
                      isSearchingCustomers || isSearchingCompanies ? (
                        <Spinner size="sm" />
                      ) : (
                        <></>
                      )
                    }
                    startContent={<Search />}
                    placeholder={t`Išči v imeniku`}
                    variant="bordered"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.currentTarget.value)}
                  />
                  <Tabs
                    aria-label={t`Izberite tip stranke`}
                    selectedKey={selectedEntityType}
                    onSelectionChange={(type) =>
                      setSelectedEntityType(type as EntityType)
                    }
                  >
                    <Tab
                      key={"physical"}
                      title={
                        <div className="flex items-center space-x-2">
                          <User />
                        </div>
                      }
                    />
                    <Tab
                      key={"legal"}
                      title={
                        <div className="flex items-center space-x-2">
                          <Building />
                        </div>
                      }
                    />
                  </Tabs>
                </div>

                <div className="max-h-[calc(100vh-113px-60px-40px-60px)] min-h-[50vh] overflow-y-auto">
                  {selectedEntityType === "physical" && !searchedCustomers && (
                    <p className="mt-12 text-center text-foreground-400">
                      <Trans>Iščite stranke</Trans>
                    </p>
                  )}
                  {selectedEntityType === "legal" && !searchedCompanies && (
                    <p className="mt-12 text-center text-foreground-400">
                      <Trans>Iščite podjetja</Trans>
                    </p>
                  )}
                  {selectedEntityType === "physical" &&
                    !isSearchingCustomers &&
                    searchedCustomers &&
                    searchedCustomers.length === 0 && (
                      <p className="mt-12 text-center text-foreground-400">
                        <Trans>Ni strank</Trans>
                      </p>
                    )}
                  {selectedEntityType === "legal" &&
                    !isSearchingCompanies &&
                    searchedCompanies &&
                    searchedCompanies.length === 0 && (
                      <p className="mt-12 text-center text-foreground-400">
                        <Trans>Ni podjetij</Trans>
                      </p>
                    )}

                  <RadioGroup
                    classNames={{
                      wrapper: "gap-0",
                    }}
                    value={selectedEntityId?.toString()}
                    onValueChange={(value) =>
                      setSelectedEntityId(Number(value))
                    }
                  >
                    {selectedEntityType === "legal" &&
                      searchedCompanies?.map((company, index) => {
                        return (
                          <CustomerCustomRadio
                            value={company.companyId.toString()}
                            index={index}
                            key={company.companyId}
                          >
                            <p className="font-semibold">
                              {company.companyName}
                            </p>
                            <p className="text-sm">{company.taxNumber}</p>
                            <p className="text-sm">
                              {company.address}, {company.zipCode}{" "}
                              {company.city}
                            </p>
                          </CustomerCustomRadio>
                        );
                      })}
                    {selectedEntityType === "physical" &&
                      searchedCustomers?.map((customer, index) => {
                        const customerGsm = (() => {
                          if (!customer.gsm || !customer.countryCode)
                            return undefined;

                          const parsedPhoneNumber =
                            numberAndCountryCodeToFullPhoneNumber(
                              customer.gsm,
                              customer.countryCode,
                            );
                          return parsedPhoneNumber;
                        })();

                        return (
                          <CustomerCustomRadio
                            value={customer.customerId.toString()}
                            index={index}
                            key={customer.customerId}
                          >
                            <p className="font-semibold">
                              {customer.name} {customer.lastName}
                            </p>
                            {customer.email && (
                              <p className="text-sm">{customer.email}</p>
                            )}
                            {customerGsm && (
                              <p className="text-sm">{customerGsm}</p>
                            )}
                          </CustomerCustomRadio>
                        );
                      })}
                  </RadioGroup>
                </div>
              </ModalBody>
              <ModalFooter className="flex-col p-2">
                <Button
                  variant="light"
                  className="h-6 w-fit py-4 pl-0"
                  size="sm"
                  onPress={onOpenCreateCustomerModal}
                >
                  <Plus className="h-6 w-6 rounded-full bg-default p-1" />
                  <p>
                    <Trans>Dodaj novo stranko</Trans>
                  </p>
                </Button>

                <Divider />

                <Button
                  type="submit"
                  className="h-12 w-full font-semibold"
                  isDisabled={!selectedEntityId}
                  onPress={() => {
                    if (selectedEntityType === "physical") {
                      handleClose({
                        customerId: selectedEntityId || undefined,
                      });
                    } else if (selectedEntityType === "legal") {
                      handleClose({
                        companyId: selectedEntityId || undefined,
                      });
                    }
                  }}
                >
                  <Trans>Dodaj stranko na račun</Trans>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <CreateCustomerModal
        isOpen={isCreateCustomerModalOpen}
        handleClose={(data: { customerId?: number; companyId?: number }) => {
          onCloseCreateCustomerModal();

          if (data.customerId || data.companyId) {
            handleClose(data);
          }
        }}
        selectedEntityType={selectedEntityType}
        preselectedEntityType={selectedEntityType}
      />
    </>
  );
};

const CreateCustomerModal = ({
  isOpen,
  handleClose,
  selectedEntityType,
  preselectedEntityType,
}: {
  isOpen: boolean;
  handleClose: ({
    customerId,
    companyId,
  }: {
    customerId?: number;
    companyId?: number;
  }) => void;
  selectedEntityType: EntityType;
  preselectedEntityType?: EntityType;
}) => {
  type PhysicalEntity = {
    personType: "physical";
    name: string;
    lastName: string;
    gsm: string;
    email: string;
    language: Language;
  };

  type LegalEntity = {
    personType: "legal";
    taxNumber: string;
    companyName: string;
    address: string;
    zipCode: string;
    city: string;
    country: string;
    isTaxSubject: boolean;
    language: Language;
  };

  type CustomerForm = PhysicalEntity | LegalEntity;

  const physicalEntityValidators: FormValidateInput<PhysicalEntity> = {
    name: (value) => (value ? null : t`Obvezno polje`),
    lastName: (value) => (value ? null : t`Obvezno polje`),
  };

  const legalEntityValidators: FormValidateInput<LegalEntity> = {
    companyName: (value) => {
      if (!value) return "Obvezno polje";
    },

    address: (value) => {
      if (!value) return "Obvezno polje";
    },

    zipCode: (value) => {
      if (!value) return "Obvezno polje";
    },

    city: (value) => {
      if (!value) return "Obvezno polje";
    },

    country: (value) => {
      if (!value) return "Obvezno polje";
    },

    taxNumber: (value) => {
      if (!value) return "Obvezno polje";
    },
  };

  const form = useForm<CustomerForm>({
    initialValues: {
      personType: preselectedEntityType ?? "physical",
      name: "",
      lastName: "",
      gsm: "",
      email: "",
      taxNumber: "",
      companyName: "",
      address: "",
      zipCode: "",
      city: "",
      country: "",
      isTaxSubject: false,
      language: "en" as Language,
    },

    validate: (selectedEntityType === "physical"
      ? physicalEntityValidators
      : legalEntityValidators) as FormValidateInput<CustomerForm>, // Cast here so we have typescript completions in the validators above
  });

  useEffect(() => {
    if (isOpen) {
      form.reset();

      if (preselectedEntityType) {
        form.setFieldValue("personType", preselectedEntityType);
      }
    }
  }, [isOpen]);

  const {
    mutateAsync: createCustomer,
    isPending: isCreatingCustomer,
    error: createCustomerError,
  } = api.customer.useAddCustomer();
  const createCustomerErrorMessage =
    createCustomerError instanceof AxiosError &&
    createCustomerError?.response?.data.error;

  const {
    mutateAsync: createCompany,
    isPending: isCreatingCompany,
    error: createCompanyError,
  } = api.company.postCompany();
  const createCompanyErrorMessage =
    createCompanyError instanceof AxiosError &&
    createCompanyError?.response?.data.error;

  const { data: languages, isFetching: isFetchingLanguages } =
    api.values.useGetLanguages();

  const handleSubmit = async (values: CustomerForm) => {
    try {
      if (values.personType === "physical") {
        const response = await createCustomer(values);

        handleClose({
          customerId: (response as any).data as number, // eslint-disable-line @typescript-eslint/no-explicit-any
        });

        notifications.show({
          message: t`Stranka je bila uspešno ustvarjena`,
        });
      } else if (values.personType === "legal") {
        const response = await createCompany(values);

        handleClose({ companyId: response.companyId });

        notifications.show({
          message: t`Stranka je bila uspešno ustvarjena`,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        notifications.show({
          message: err.message,
          color: Color.Error,
        });
      }
    }
  };

  const formValues = form.getValues();

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={() => handleClose({})}
      closeButton={<></>}
    >
      <ModalContent>
        {(onClose) => (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <ModalHeader className="flex-col">
              <Trans>Dodaj novo stranko</Trans>

              <div className="mb-6 mt-8 flex justify-center">
                <Tabs
                  radius="full"
                  selectedKey={form.getValues().personType}
                  onSelectionChange={(type) =>
                    form.setFieldValue(
                      "personType",
                      type as CustomerForm["personType"],
                    )
                  }
                >
                  <Tab
                    key={"physical"}
                    title={t`Fizična oseba`}
                    className="px-4"
                  />
                  <Tab key={"legal"} title={t`Pravna oseba`} className="px-4" />
                </Tabs>
              </div>
            </ModalHeader>
            <ModalBody>
              {formValues.personType === "physical" ? (
                <>
                  <LimeInput
                    label={t`Ime`}
                    variant="bordered"
                    {...form.getInputProps("name")}
                    autoFocus
                  />
                  <LimeInput
                    label={t`Priimek`}
                    variant="bordered"
                    {...form.getInputProps("lastName")}
                  />

                  <LimePhoneInput
                    {...form.getInputProps("gsm")}
                    defaultCountry={"SI"}
                    label={t`Telefon`}
                    error={
                      formValues.gsm && !isValidPhoneNumber(formValues.gsm)
                        ? t`Telefonska številka ni veljavna`
                        : undefined
                    }
                  />

                  <LimeInput
                    label={t`Email`}
                    variant="bordered"
                    {...form.getInputProps("email")}
                  />
                </>
              ) : (
                <>
                  <LimeInput
                    label={t`Davčna številka podjetja`}
                    variant="bordered"
                    {...form.getInputProps("taxNumber")}
                  />
                  <LimeInput
                    label={t`Ime podjetja`}
                    variant="bordered"
                    {...form.getInputProps("companyName")}
                  />
                  <LimeInput
                    label={t`Naslov podjetja`}
                    variant="bordered"
                    {...form.getInputProps("address")}
                  />
                  <LimeInput
                    label={t`Pošta podjetja`}
                    variant="bordered"
                    {...form.getInputProps("zipCode")}
                  />
                  <LimeInput
                    label={t`Kraj sedeža podjetja`}
                    variant="bordered"
                    {...form.getInputProps("city")}
                  />
                  <LimeInput
                    label={t`Država sedeža podjetja`}
                    variant="bordered"
                    {...form.getInputProps("country")}
                  />
                  <LimeSwitch
                    title={t`Davčni zavezanec`}
                    {...form.getInputProps("isTaxSubject")}
                    className="rounded-xl"
                  ></LimeSwitch>
                </>
              )}

              <LimeSingleSelect
                items={
                  languages?.map((language) => ({
                    label: language,
                    key: language,
                  })) || []
                }
                {...form.getInputProps("language")}
                label={t`Jezik`}
                variant="bordered"
              />

              {createCustomerErrorMessage && (
                <ErrorCard message={createCustomerErrorMessage} />
              )}

              {createCompanyErrorMessage && (
                <ErrorCard message={createCompanyErrorMessage} />
              )}
            </ModalBody>
            <ModalFooter className="flex-col p-2">
              <Divider className="mt-2" />

              <Button
                type="submit"
                className="h-12 w-full font-semibold"
                isLoading={isCreatingCustomer || isCreatingCompany}
              >
                <Trans>Dodaj novo stranko</Trans>
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};

const CustomerCustomRadio = (
  props: RadioProps & {
    index: number;
  },
) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          "inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between",
          "flex-row-reverse w-full cursor-pointer gap-4 p-4 border-b-2 border-secondary max-w-full",
          // "data-[selected=true]:border-primary",
          { "border-t-2": props.index === 0 },
        ),
      }}
    >
      {children}
    </Radio>
  );
};
