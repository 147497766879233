import { apiGetAppointment } from "@/Components/AppointmentModal/apiAppointment";
import { api } from "@/lib/api-client";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Modal,
  Space,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { MdErrorOutline } from "react-icons/md";
import { RiUserLine } from "react-icons/ri";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { getStartTimeOfServiceInLinked } from "../../../utils";
import { AxiosError } from "axios";

type AppointmentDetailsType = {
  appointmentParts: {
    serviceId: number;
    blockBeforeMins: number;
    blockAfterMins: number;
    duration: number;
    resources: number[];
    oldResources: number[];
    users: number[];
    oldUsers: number[];
    appointmentId: number;
  }[];
};

export const RescheduleModal = () => {
  const {
    rescheduleData,
    setRescheduleData,
    refreshCalendar,
    hasRescheduleNotification,
    calendarMode,
    users,
    resources,
    services,
  } = useCalendarStore((state) => state);

  const rescheduleForm = useForm({
    initialValues: {
      rescheduleAllLinked: true,
      allLinkedSameUserOrResource: false,
      sendNotifications: true,
      overrideUserService: false,
      overrideResourceService: false,
    },
  });

  const { mutateAsync: rescheduleAppointment } =
    api.appointments.useRescheduleAppointment();

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const [appointment, setAppointment] = useState<AppointmentDetailsType | null>(
    null,
  );

  useEffect(() => {
    if (!rescheduleData?.appointment?.appointmentId) {
      setAppointment(null);
      return;
    }

    checkRescheduleValidity();
  }, [rescheduleData]);

  const checkRescheduleValidity = async () => {
    if (!rescheduleData.appointment) return;

    const { date, startTime, appointmentId, users } =
      rescheduleData.appointment;

    try {
      await rescheduleAppointment({
        appointmentId,
        body: {
          startTime: `${date}T${startTime}`,
          allLinkedSameUserOrResources:
            rescheduleForm.values.allLinkedSameUserOrResource,
          rescheduleAllLinked: rescheduleForm.values.rescheduleAllLinked,
          sendRescheduleNotifications: rescheduleForm.values.sendNotifications,
          users,
          resources: rescheduleData.appointment.resources,
          checkOnly: true,
        },
      });

      fetchAppointment();
    } catch (e) {
      notifications.show({
        message: t`Napaka`,
        color: "red",
      });

      setRescheduleData(null);
      await refreshCalendar();
    }
  };

  const fetchAppointment = async () => {
    try {
      const response = await apiGetAppointment(
        rescheduleData.appointment.appointmentId,
      );

      const app = response.data.appointment;

      setAppointment(app);
    } catch (e) {
      console.log("error");
    }
  };

  const handleSubmit = async () => {
    const { date, startTime, appointmentId, users } =
      rescheduleData.appointment;

    try {
      await rescheduleAppointment({
        appointmentId,
        body: {
          startTime: `${date}T${startTime}`,
          allLinkedSameUserOrResources:
            rescheduleForm.values.allLinkedSameUserOrResource,
          rescheduleAllLinked: rescheduleForm.values.rescheduleAllLinked,
          sendRescheduleNotifications: rescheduleForm.values.sendNotifications,
          users,
          resources: rescheduleData.appointment.resources,
        },
      });

      setRescheduleData(null);
      await refreshCalendar();
    } catch (e) {
      if (e instanceof AxiosError) {
        notifications.show({
          title: t`Napaka pri prestavljanju termina`,
          message: e.response?.data.error,
          color: "red",
        });
      } else {
        notifications.show({
          message: t`Napaka pri prestavljanju termina`,
          color: "red",
        });
      }

      setRescheduleData(null);
      await refreshCalendar();
    }
  };

  if (!rescheduleData || !appointment) return null;
  const newAppointmentsWithUsersAndResources = appointment.appointmentParts
    .filter(
      (appPart) =>
        rescheduleForm.values.rescheduleAllLinked ||
        appPart.appointmentId === rescheduleData.appointment.appointmentId,
    )
    .map((part) => {
      return {
        ...part,
        oldResources: part.resources,
        oldUsers: part.users,
        users:
          rescheduleForm.values.allLinkedSameUserOrResource ||
          rescheduleData.appointment.appointmentId === part.appointmentId
            ? rescheduleData.appointment.users || part.users
            : part.users,
        resources: rescheduleForm.values.allLinkedSameUserOrResource
          ? rescheduleData.appointment.resources || part.resources
          : part.resources,
        startDuration: part.duration,
        endDuration: null,
        timeOffDuration: null,
        removed: false,
      };
    });

  const newAppointmentsWithTimes = newAppointmentsWithUsersAndResources.map(
    (part, i) => {
      const service = services.find(
        (s: { serviceId: number }) => s.serviceId === part.serviceId,
      );

      const isUserValidForService =
        calendarMode === "users" && service.doesRequireUser
          ? part.users.every((usr: number) => {
              const userDetails = users.find(
                (u: { userId: number }) => u.userId === usr,
              );

              return userDetails?.userServices.find(
                (us: { serviceId: number }) => us.serviceId === part.serviceId,
              );
            })
          : true;

      const isResourceValidForService =
        calendarMode === "resources"
          ? part.resources.every((res: number) => {
              const resourceDetails = resources.find(
                (r: { resourceId: number }) => r.resourceId === res,
              );

              return resourceDetails?.resourceGroup.resourceGroupServices.find(
                (rgs: { serviceId: number }) =>
                  rgs.serviceId === part.serviceId,
              );
            })
          : true;

      return {
        ...part,
        startTime: getStartTimeOfServiceInLinked({
          selectedServices: newAppointmentsWithUsersAndResources,
          index: i,
          startTime: `${rescheduleData.appointment.date}T${rescheduleData.appointment.startTime}`,
        }),
        isUserValidForService,
        isResourceValidForService,
        serviceName: service.name,
      };
    },
  );

  const rescheduleUserServiceWarning = newAppointmentsWithTimes.some(
    (app) => !app.isUserValidForService,
  );

  const isButtonDisabledRelations = (() => {
    if (permissions.restrict_appointment_services) {
      if (
        rescheduleForm.values.overrideUserService ||
        rescheduleForm.values.overrideResourceService
      ) {
        return true;
      }
      return false;
    }

    if (newAppointmentsWithTimes.some((a) => !a.isUserValidForService)) {
      if (
        rescheduleForm.values.overrideUserService ||
        rescheduleForm.values.overrideResourceService
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  })();

  return (
    <Modal
      opened={rescheduleData}
      onClose={() => {
        if (rescheduleData) {
          setRescheduleData(null);
          refreshCalendar();
        }
      }}
      title={t`Prestavitev termina`}
    >
      <form onSubmit={rescheduleForm.onSubmit(handleSubmit)}>
        <Text variant={TextVariant.Body}>
          Termin bo prestavljen na{" "}
          <Text variant={TextVariant.BodyEmphasized} inline span>
            {rescheduleData?.appointment.formattedData.date}
          </Text>{" "}
          ob{" "}
          <Text variant={TextVariant.BodyEmphasized} inline span>
            {rescheduleData?.appointment.formattedData.startTime}
          </Text>
          .
        </Text>

        <Space h={"md"} />

        <Text variant={TextVariant.Body}>
          Prestavljate termin za naslednje osebe:
        </Text>
        {rescheduleData?.appointment?.customers.map(
          (customer: { name: string; lastName: string; id: number }) => (
            <Flex key={customer.id} py={"xs"} align={"center"} gap={"xs"}>
              <RiUserLine size={".9rem"} />
              <Text variant={TextVariant.Body}>
                {customer.name} {customer.lastName}
              </Text>
            </Flex>
          ),
        )}

        {hasRescheduleNotification && (
          <>
            <Divider mb={"md"} />
            <Checkbox
              label={t`Pošlji obvestila?`}
              {...rescheduleForm.getInputProps("sendNotifications", {
                type: "checkbox",
              })}
            />
          </>
        )}

        {rescheduleData?.appointment?.FK_linkedAppointment && (
          <>
            <Divider mb={"xs"} mt={"md"} />
            <Flex direction={"column"} mt={"md"} gap={"sm"}>
              <Text variant={TextVariant.BodyEmphasized}>
                Termin je povezan z ostalimi.
              </Text>
              <Checkbox
                label={t`Prestavi vse povezane termine?`}
                {...rescheduleForm.getInputProps("rescheduleAllLinked", {
                  type: "checkbox",
                })}
              />
              <Collapse in={rescheduleForm.values.rescheduleAllLinked} ml={10}>
                <Checkbox
                  label={t`Prestavi vse povezane termine k istemu zaposlenemu?`}
                  {...rescheduleForm.getInputProps(
                    "allLinkedSameUserOrResource",
                    {
                      type: "checkbox",
                    },
                  )}
                />
              </Collapse>
            </Flex>
          </>
        )}

        <Divider my={"md"} />

        <Flex direction={"column"} gap={20}>
          <Text variant={TextVariant.BodyEmphasized}>Storitve v terminu</Text>

          <Collapse in={rescheduleUserServiceWarning}>
            <Flex
              justify={"space-between"}
              wrap={"wrap"}
              styles={{
                root: {
                  background: "rgb(254, 242, 242)",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  border: "1px solid var(--error)",
                },
              }}
            >
              <Text variant={TextVariant.BodyEmphasized} c={Color.Error}>
                <Trans>Zaposleni ne izvajajo storitev</Trans>
              </Text>
              {!permissions.restrict_appointment_services ? (
                <Checkbox
                  {...rescheduleForm.getInputProps("overrideUserService", {
                    type: "checkbox",
                  })}
                  label={t`Prestavi`}
                ></Checkbox>
              ) : null}
            </Flex>
          </Collapse>

          {newAppointmentsWithTimes.map((a) =>
            calendarMode === "users" ? (
              <Flex
                direction={"column"}
                styles={{
                  root: { background: "#FAFAFA", padding: "5px 10px" },
                }}
                gap={0}
              >
                <Collapse in={!a.isUserValidForService}>
                  {" "}
                  <Flex gap={10} c={Color.Error} mb={10}>
                    <MdErrorOutline></MdErrorOutline>
                    <Text variant={TextVariant.Caption}>
                      <Trans>Vsi zaposleni ne izvajajo storitve</Trans>
                    </Text>
                  </Flex>{" "}
                </Collapse>
                <Text variant={TextVariant.BodyEmphasized}>
                  <Trans>Storitev</Trans>: {a.serviceName}
                </Text>
                <Flex justify={"space-between"}>
                  <Text variant={TextVariant.BodyEmphasized}>
                    <Trans>Izvajalci</Trans>
                  </Text>
                </Flex>
                <Flex gap={10} c={Color.PrimaryText} align={"center"}>
                  <Flex direction={"column"}>
                    {a.oldUsers.map((user: number) => (
                      <Text
                        variant={TextVariant.Body}
                        td={"line-through"}
                        key={user}
                      >
                        {`${
                          users.find(
                            (u: { userId: number }) => u.userId === user,
                          )?.name || ""
                        } ${
                          users.find(
                            (u: { userId: number }) => u.userId === user,
                          )?.lastName || ""
                        }`}
                      </Text>
                    ))}
                  </Flex>
                  {"->"}
                  <Flex direction={"column"}>
                    {a.users.map((user: number) => (
                      <Text variant={TextVariant.Body} key={user}>
                        {`${
                          users.find(
                            (u: { userId: number }) => u.userId === user,
                          )?.name || ""
                        } ${
                          users.find(
                            (u: { userId: number }) => u.userId === user,
                          )?.lastName || ""
                        }`}
                      </Text>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <Flex direction={"column"}>
                <Text variant={TextVariant.BodyEmphasized}>
                  <Trans>Sredstva</Trans>
                </Text>
                <Flex gap={10} c={Color.PrimaryText} align={"center"}>
                  <Flex direction={"column"}>
                    {rescheduleData?.appointment?.oldResources.map(
                      (resource: number) => (
                        <Text
                          variant={TextVariant.Body}
                          td={"line-through"}
                          key={resource}
                        >
                          {
                            resources.find(
                              (r: { id: number }) => r.id === resource,
                            ).label
                          }
                        </Text>
                      ),
                    )}
                  </Flex>
                  {"->"}
                  <Flex direction={"column"}>
                    {rescheduleData?.appointment?.resources.map(
                      (resource: number) => (
                        <Text variant={TextVariant.Body} key={resource}>
                          {
                            resources.find(
                              (r: { id: number }) => r.id === resource,
                            ).label
                          }
                        </Text>
                      ),
                    )}
                  </Flex>
                </Flex>
              </Flex>
            ),
          )}
        </Flex>

        <Space h={20}></Space>
        <Flex
          justify={"flex-end"}
          gap={"md"}
          pos={"sticky"}
          bottom={0}
          inset={0}
          styles={{
            root: {
              background: "white",
              padding: "10px 10px",
              borderTop: "1px solid #eaebed",
            },
          }}
        >
          <Button
            variant="white"
            onClick={() => {
              setRescheduleData(null);
              refreshCalendar();
            }}
          >
            <Trans>Prekliči</Trans>
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isButtonDisabledRelations}
            data-identifier="confirmRescheduleButton"
          >
            <Trans>Prestavi</Trans>
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
